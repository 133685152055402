<template>
 <sdCards
  :bodyStyle="{
      backgroundColor: '#F8F9FB',
      borderRadius: '20px',
    }"
  headless
 >
  <OrderSummary>
   <sdHeading as="h4" class="summary-table-title"> Offerte Overzicht</sdHeading>
   <sdCards
    :bodyStyle="{
          backgroundColor: '#ffffff',
          borderRadius: '20px',
        }"
    headless
   >
    <div v-if="!data"
         class="order-summary-inner">
     <a-skeleton active/>
    </div>
    <div v-else-if="!orderFlow" class="order-summary-inner">
     <ul class="summary-list">
      <li>Bespaarplan zonnepanelen pakket met {{ type }}
       {{ getPanelAttribute(data.quote, 'panel', 'count') }} panelen
      </li>
      <li v-if="getPanelAttribute(data.quote, 'panel', 'totalPrice') > 0">
       <span class="summary-list-title" style="padding-left: 10px;font-size: 90%;color:#000000">{{
         getPanelAttribute(data.quote, 'panel', 'count')
        }} x {{ getPanelAttribute(data.quote, 'panel', 'name') }}:</span>
       <!--       <span v-if="!data.calculation.product.isProxy || type === 'SOLARCONFIRM'" class="summary-list-text"-->
       <!--             style="font-size: 90%;color:#000000">{{-->
       <!--         `${new Intl.NumberFormat('nl-NL', {-->
       <!--          style: 'currency',-->
       <!--          currency: 'EUR'-->
       <!--         }).format(getPanelAttribute(data.quote, 'panel', 'totalPrice'))}`-->
       <!--        }}-->
       <!--       </span>-->
      </li>
      <template v-for="(item,index) in data.quote" :key="index">
       <li v-if="item && item.type && item.type !== 'PANEL' && item.type !== 'panel'">
        <span class="summary-list-title" style="padding-left: 10px;font-size: 90%;color:#000000"><span
         v-if="item.totalPrice < 1">- </span>{{ item.name }}</span>
        <!--        <span v-if="item.totalPrice > 0" class="summary-list-text" style="font-size: 90%;color:#000000">{{-->
        <!--          `${new Intl.NumberFormat('nl-NL', {-->
        <!--           style: 'currency',-->
        <!--           currency: 'EUR'-->
        <!--          }).format(getPanelAttribute(data.quote, 'total', 'totalPrice'))}`-->
        <!--         }}-->
        <!--       </span>-->
       </li>
      </template>
      <li>
       <hr v-if="!data.calculation.product.isProxy || type === 'SOLARCONFIRM'" style="width:100%">
      </li>
     </ul>
     <sdHeading v-if="!data.calculation.product.isProxy || type === 'SOLARCONFIRM'" as="h4" class="summary-total mt-5">
      <span class="summary-total-label" style="color:#000000">Totaal : </span>
      <span class="summary-total-amount" style="color:#000000">{{
        `${new Intl.NumberFormat('nl-NL', {
         style: 'currency',
         currency: 'EUR'
        }).format(getPanelAttribute(data.quote, 'total', 'totalPrice'))}`
       }}</span>
     </sdHeading>
    </div>
    <div v-else-if="orderFlow" class="order-summary-inner">
     <ul class="summary-list">
      <li>{{ data.product.name }} pakket met
       {{ getPanelAttribute(data.calculation, 'panel', 'count') }} panelen
      </li>
      <li v-if="getPanelAttribute(data.calculation, 'panel', 'totalPrice') > 0">
       <span class="summary-list-title" style="padding-left: 10px;font-size: 90%;color:#000000">{{
         getPanelAttribute(data.calculation, 'panel', 'count')
        }} x {{ getPanelAttribute(data.calculation, 'panel', 'name') }}:</span>
       <span v-if="!data.product.isProxy" class="summary-list-text" style="font-size: 90%;color:#000000">{{
         `${new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR'
         }).format(getPanelAttribute(data.calculation, 'panel', 'totalPrice'))}`
        }}
       </span>
      </li>
      <template v-for="(item,index) in data.quote" :key="index">
       <li v-if="item && item.type && item.type !== 'PANEL' && item.type !== 'panel' && item.productID">
        <span class="summary-list-title"
              style="padding-left: 10px;font-size: 90%;color:#000000"><span
         v-if="item.totalPrice < 1">- </span>{{ item.name }}</span>
        <span v-if="item.totalPrice > 0" class="summary-list-text" style="font-size: 90%;color:#000000">{{
          `${new Intl.NumberFormat('nl-NL', {
           style: 'currency',
           currency: 'EUR'
          }).format(item.totalPrice)}`
         }}
       </span>
       </li>
      </template>
      <li>
       <hr v-if="!data.product.isProxy || type === 'SOLARCONFIRM'" style="width:100%">
      </li>
     </ul>
     <sdHeading v-if="!data.product.isProxy || type === 'SOLARCONFIRM'" as="h4" class="summary-total mt-5">
      <span class="summary-total-label" style="color:#000000">Totaal : </span>
      <span class="summary-total-amount" style="color:#000000">{{
        `${new Intl.NumberFormat('nl-NL', {
         style: 'currency',
         currency: 'EUR'
        }).format(data.calculation.total)}`
       }}</span>
     </sdHeading>
    </div>
   </sdCards>
  </OrderSummary>
 </sdCards>
</template>
<script>
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
import {OrderSummary} from "@/view/orders/component/style";
import {getPanelAttribute} from "@/utility/solarUtility";

const ProductPriceSolarInfoCard = defineComponent({
 name: 'ProductPriceSolarInfoCard',
 components: {
  OrderSummary,
 },
 props: {
  data: VueTypes.object.def(null),
  priceSummary: VueTypes.bool.def(false),
  orderFlow: VueTypes.bool.def(false),
  type: VueTypes.string.def('')
 },
 setup() {
  return {
   getPanelAttribute,
  }
 }
});

export default ProductPriceSolarInfoCard;
</script>
