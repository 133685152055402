<template>
 <TableStyleWrapper2>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="roofSelectionTableColumns"
    :dataSource="roofSelectionTableData"
    :pagination="false"
   />
  </TableWrapper>
 </TableStyleWrapper2>
</template>
<script>
import {TableStyleWrapper2, TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import VueTypes from "vue-types";
import {formatterShadowLossText, getRoofPotentialInfo} from "@/utility/utility";

const roofSelectionTableColumns = [
 {
  title: 'Dakdeel',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Instralingsverlies',
  dataIndex: 'irradiationLoss',
  key: 'irradiationLoss',
 },
 {
  title: 'Dakhelling',
  dataIndex: 'inclination',
  key: 'inclination',
 },
 {
  title: 'Oriëntatie',
  dataIndex: 'orientation',
  key: 'orientation',
 },
 {
  title: 'Oppervlak',
  dataIndex: 'area',
  key: 'area',
 },
 {
  title: 'Dakgoothoogte',
  dataIndex: 'gutter',
  key: 'gutter',
 },
];
const RoofSelectionTable = defineComponent({
 name: 'RoofSelectionTable',
 components: {TableStyleWrapper2, TableWrapper},
 props: {
  data: VueTypes.object,
 },
 setup(props) {
  const count = 0

  const roofSelectionTableData = computed(() =>
   props.data.map((roofSelection) => {
    const {properties} = roofSelection;

    return {
     key: properties.id,
     id: (<div>
      <div>Dakdeel {properties.ID} - {getRoofPotentialInfo(properties.Roofpotential).potential}</div>
      <div>{formatterShadowLossText(properties.ShadowLoss)}</div>
     </div>),
     irradiationLoss: <span>{getRoofPotentialInfo(properties.Roofpotential).irradiationLoss}</span>,
     inclination: <span>{properties.Inclination}°</span>,
     orientation: <span>{properties.Orientation}</span>,
     area: <span>{properties.Area}</span>,
     gutter: <span>{properties.Gutter}m</span>,
    };
   }),
  );

  return {
   roofSelectionTableColumns,
   roofSelectionTableData,
   count,
   getRoofPotentialInfo,
   formatterShadowLossText,
  };
 },
});

export default RoofSelectionTable;
</script>
