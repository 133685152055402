<template>
 <div>
  <PdfViewer ref="pdfViewer"></PdfViewer>
  <sdPageHeader title="Order">
  </sdPageHeader>
  <Main v-if="errorOrder">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/order')">
       Naar orders
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-else>
   <sdModal :onCancel="handleSignManualModalCancel" :visible="showSignManual"
            title="Manueel tekenen" type="primary">
    <ModalMain>
     <div class="create-account-form">
      <a-form :model="signManual" layout="vertical" @finish="handleSignManual">
       <a-row :gutter="30">
        <a-col
         :xs="24"
        >
         <a-form-item
          :rules="{required: true,message: 'Dit veld is verplicht'}"
          label="Omschrijving"
          name="description"
         >
          <a-textarea
           v-model:value="signManual.description"
           :rows="5"
           name="description"
          />
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-button
          html-type="submit"
          size="large"
          style="margin-top: 16px"
          type="primary"
         >
          Tekenen
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </div>
    </ModalMain>
   </sdModal>
   <sdModal :onCancel="handleFileUploadCancel" :visible="showFileUploadRef" title="Document uploaden" type="primary">
    <ModalMain>
     <div class="create-account-form">
      <a-form :model="fileUpload" layout="vertical" @finish="handleUpload">
       <a-row :gutter="30">
        <a-col :xs="24">
         <div class="clearfix">
          <a-upload-dragger :before-upload="beforeUpload" :file-list="fileList" :remove="handleRemove"
                            multiple="false">
           <a-button :disabled="fileList.length === 1">
            <upload-outlined></upload-outlined>
            Selecteer bestand
           </a-button>
          </a-upload-dragger>
         </div>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type" name="type">
          <a-select v-model:value="fileUpload.type" :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in fileOptions" :key="index" :value="item.value" name="type">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-button
          :disabled="fileList.length === 0"
          :loading="loadingOrderFileUpload"
          html-type="submit"
          size="large"
          style="margin-top: 16px"
          type="primary"
         >
          {{ uploading ? 'Uploading' : 'Start Upload' }}
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </div>
    </ModalMain>
   </sdModal>
   <sdModal :onCancel="handleCancelModal" :visible="showCancelRef"
            title="Annuleren" type="primary">
    <ModalMain>
     <div class="create-account-form">
      <a-form :model="cancelOrder" layout="vertical" @finish="handleCancelOrder">
       <a-row :gutter="30">
        <a-col
         v-if="statusses.filter(i => i.status ==='CANCELLED').length > 0 && statusses.filter(i => i.status ==='CANCELLED')[0].subStatusses.length > 0"
         :xs="24"
        >
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Substatus"
                      name="subStatusID">
          <a-select v-model:value="cancelOrder.subStatusID" :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in statusses.filter(i => i.status ==='CANCELLED')[0].subStatusses"
                            :key="index"
                            :value="item.id" name="subStatusID">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col
         v-if="order & order.abilities && order.abilities.actions.canCancelAtSupplier"
         :key="key"
         :xs="24"
        >
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Annuleren bij leverancier"
                      name="cancelAtSupplier">
          <a-select v-model:value="cancelOrder.cancelAtSupplier" :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in defaultOptions"
                            :key="index"
                            :value="item.value" name="cancelAtSupplier">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-form-item
          :rules="{required: true,message: 'Dit veld is verplicht'}"
          label="Omschrijving"
          name="description"
         >
          <a-textarea
           v-model:value="cancelOrder.description"
           :rows="5"
           name="description"
          />
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-button
          html-type="submit"
          size="large"
          style="margin-top: 16px"
          type="primary"
         >
          Opslaan
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </div>
    </ModalMain>
   </sdModal>
   <sdModal :onCancel="handleStatusModalCancel" :visible="showChangeStatus" title="Status aanpassen" type="primary">
    <ModalMain>
     <div class="create-account-form">
      <a-form :model="statusChangeOrder" layout="vertical" @finish="handleStatusChangeOrder">
       <a-row :gutter="30">
        <a-col :xs="24">
         <sdAlerts
          :closable="false"
          :outlined="true"
          :showIcon="true"
          class="mb-5 mt-0"
          description="Status wijzigingen worden niet doorgegeven aan de leverancier van het verkochte product."
          message=""
          type="info"
         />
        </a-col>
        <a-col :xs="24">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Status"
                      name="status">
          <a-select v-model:value="statusChangeOrder.status" :allowClear="true" class="sDash_fullwidth-select"
                    size="large" @change="onStatusChange">
           <a-select-option v-for="(item,index) in statusses"
                            :key="index"
                            :value="item.status" name="status">
            {{ item.displayName }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col v-if="subStatusses.length > 0" :xs="24">
         <a-form-item label="Substatus" name="subStatusID">
          <a-select v-model:value="statusChangeOrder.subStatusID" :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in subStatusses"
                            :key="index"
                            :value="item.id" name="subStatusID">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-form-item
          :rules="{required: true,message: 'Dit veld is verplicht'}"
          label="Description"
          name="description"
         >
          <a-textarea
           v-model:value="statusChangeOrder.description"
           :rows="5"
           name="description"
          />
         </a-form-item>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-button
          html-type="submit"
          size="large"
          style="margin-top: 16px"
          type="primary"
         >
          Opslaan
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </div>
    </ModalMain>
   </sdModal>
   <OrderDetailShop v-if="order && order.type=== 'SHOP'"
                    :activity="activity"
                    :communication="communication"
                    :flowQuestions="flowQuestions"
                    :order="order"
                    :script="script"
                    @handleAction="handleAction"
                    @showFileUpload="showFileUpload"
                    @showPdfViewer="showPdfViewer"/>
   <TabBasic
    v-else
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
   >
    <Child :key="1">
     <template #tab>
        <span>
          <sdFeatherIcons size="18" type="shopping-bag"/>
          Overview
        </span>
     </template>
     <div>
      <a-row :gutter="25">
       <a-col v-if="order?.type !== 'LEAD'" :md="12" :xs="24">
        <Suspense v-if="order && order.type && (order.type === 'HYDROMX')">
         <sdCards title="Product">
          <div class="card productRevision-card">
           <figure>
            <img alt="" src="https://www.hydromx.com/wp-content/uploads/2021/09/hydromx_logo.png"
                 style="max-width: 200px"/>
           </figure>
           <figcaption>
            <div class="card__content" style="margin-top:20px">
             Efficiënt Rendement vervangt de bestaande vloeistof in jouw cv-installatie met het gepatenteerde Hydromx®.
             Hydromx® is speciaal ontwikkeld om cv-ketels, en (hybride) warmtepompen efficiënter te laten werken.
             Hierdoor gaan ze niet alleen langer mee, maar worden ze ook direct beschermd tegen corrosie en kalkaanslag.
             <br/><br/>
             Doordat Hydromx® sneller opwarmt en zijn warmte beter behoudt, is deze speciale nanovloeistof niet alleen
             schoner, maar verbetert het de warmteoverdracht aanzienlijk waardoor het gas- of stroomverbruik tot wel 35%
             wordt verminderd. Efficiënt Rendement dus!
            </div>
           </figcaption>
          </div>
         </sdCards>
        </Suspense>
        <Suspense v-if="order && order.type && (order.type === 'SVM' || order.type === 'ENERGY')">
         <template #default>
          <ProductInfoCard :order="order"/>
         </template>
        </Suspense>
        <Suspense v-if="order && order.type && order.type === 'SOLARSIMPLE'">
         <template #default>
          <ProductPriceSolarSimpleInfoCard :order="order"/>
         </template>
        </Suspense>
        <Suspense v-if="order && order.type && order.type === 'SOLAR'">
         <template #default>
          <ProductPriceSolarInfoCard v-if="solarData && solarData.quote" :data="solarData" :priceSummary="true"/>
         </template>
        </Suspense>
        <Suspense v-if="order && order.type && order.type === 'SOLARCONFIRM'">
         <template #default>
          <ProductPriceSolarConfirmInfoCard v-if="solarData && solarData.quote" :data="solarData" :priceSummary="true"
                                            :type="order.type"/>
         </template>
        </Suspense>
        <Suspense v-if="order && order.type && (order.type === 'ENERGY' || order.type === 'SOLARSIMPLE')">
         <template #default>
          <RelationCard :order="order"/>
         </template>
        </Suspense>
        <Suspense v-if="order && order.type && order.type === 'SVM'">
         <template #default>
          <RelationSVMCard :order="order"/>
         </template>
        </Suspense>
       </a-col>
       <a-col :md="12" :xs="24">
        <sdCards title="Informatie">
         <a-skeleton v-if="!order" active/>
         <a-row v-else :gutter="30">
          <a-col :md="12" :xs="24" class="mb-25">
           <InfoWrapper>
            <div class="info-title">Status</div>
            <div class="info-text">{{ order.statusName }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.externalReference">
            <div class="info-title">Uitgaande ID</div>
            <div class="info-text">{{ order.externalReference }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.subStatusName">
            <div class="info-title">Substatus</div>
            <div class="info-text">{{ order.subStatusName }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order?.type === 'LEAD'">
            <div class="info-title">Product</div>
            <div class="info-text">Lead</div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Datum aangemaakt</div>
            <div class="info-text">{{ moment(order.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Datum geupdate</div>
            <div class="info-text">{{ moment(order.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.isSigned" :key="order.isSigned">
            <div class="info-title">Contract tekendatum</div>
            <div class="info-text">{{ moment(order.signTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.expiryTimestamp">
            <div class="info-title">Verloopt op</div>
            <div class="info-text">{{ moment(order.expiryTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.isCancelled">
            <div class="info-title">Datum geannuleerd</div>
            <div class="info-text">{{
              moment(order.cancellationTimestamp).format('DD-MM-YYYY HH:mm:ss')
             }}
            </div>
           </InfoWrapper>
           <InfoWrapper v-if="order.isCancelled && order.cancellationBy">
            <div class="info-title">Geannuleerd door</div>
            <div class="info-text">{{ order.cancellationBy }}<br/><small>{{ order.cancellationIP }}</small>
            </div>
           </InfoWrapper>
           <InfoWrapper v-if="order.isSent">
            <div class="info-title">Datum verzonden</div>
            <div class="info-text">{{ moment(order.sentTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
           </InfoWrapper>
           <InfoWrapper v-if="order.isSent && order.sentBy">
            <div class="info-title">Verzonden door</div>
            <div class="info-text">{{ order.sentBy }}<br/><small>{{ order.sentIP }}</small></div>
           </InfoWrapper>
          </a-col>
          <a-col :md="12" :xs="24" class="mb-25">
           <InfoWrapper>
            <div class="info-title">Consument / Zakelijk</div>
            <div class="info-text">{{
              order.relationRevision.type === 'CONSUMER' ? 'Consument' : ''
             }}{{ order.relationRevision.type === 'BUSINESS' ? 'Zakelijk' : '' }}
            </div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Type</div>
            <div class="info-text">{{
              order.signType === 'ESIGNATURE' ? 'Offerte' : ''
             }}{{ order.signType === 'SIGNATURE' ? 'Overeenkomst' : '' }}
            </div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Flow</div>
            <div class="info-text">{{
              order.type === 'ENERGY' ? 'Energie' : ''
             }}{{ order.type === 'SVM' ? 'SVM subsidie' : '' }}
            </div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Organisatie</div>
            <div class="info-text">{{ order.organizationName }}</div>
           </InfoWrapper>
           <InfoWrapper>
            <div class="info-title">Agent</div>
            <div class="info-text">{{ order.userFullName }}</div>
           </InfoWrapper>
          </a-col>
         </a-row>
        </sdCards>
       </a-col>
       <a-col :md="12" :xs="24">
        <sdCards
         v-if="(order?.type === 'SOLAR' && order?.productRevision.isProxy && order?.abilities?.actions?.canCreateSolarConfirm) || order?.type !== 'SOLAR' || order?.type === 'SOLAR' && !order?.productRevision.isProxy"
         title="Acties">
         <a-row :gutter="20">
          <!--          <a-col-->
          <!--           v-if="order && order.isSigned && order.type === 'SOLAR' && !order.isCancelled && order.productRevision.isProxy"-->
          <!--           :lg="24"-->
          <!--           :md="24" :xl="24" :xs="24" :xxl="12"-->
          <!--           class="mb-5">-->
          <!--           <div class="sDash_form-action">-->
          <!--            <a-popconfirm-->
          <!--             cancel-text="Nee"-->
          <!--             ok-text="Ja"-->
          <!--             placement="leftTop"-->
          <!--             title="Weet je het zeker? Nadat de bespaarplan is aangemaakt word je naar de order gerouteerd."-->
          <!--             @confirm="handleAction('CREATECONFIRMORDER')"-->
          <!--            >-->
          <!--             <a-button class="btn-signin w-100" size="large" type="primary">-->
          <!--              Bespaarplan aanmaken-->
          <!--             </a-button>-->
          <!--            </a-popconfirm>-->
          <!--           </div>-->
          <!--          </a-col>-->
          <a-col v-if="order && !order.isSigned" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-button class="btn-signin w-100" size="large" type="primary"
                      @click.prevent="handleAction('SHOWOFFERDOCUMENT')"> Aanbod inzien
            </a-button>
           </div>
          </a-col>
          <a-col v-if="order && order.abilities.actions.canReOffer && order.type !== 'HYDROMX'" :lg="24" :md="24"
                 :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-button class="btn-signin w-100" size="large" type="primary"
                      @click.prevent="handleAction('REOFFER')"> Klant een nieuw aanbod doen
            </a-button>
           </div>
          </a-col>
          <a-col v-if="order && order.abilities.actions.canChangeStatus" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-button class="btn-signin w-100" size="large" type="primary"
                      @click.prevent="handleAction('CHANGESTATUS')"> Status aanpassen
            </a-button>
           </div>
          </a-col>
          <a-col :lg="24" :md="24" :xl="24" :xs="24"
                 :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-popconfirm
             cancel-text="Nee"
             ok-text="Ja"
             placement="leftTop"
             title="Weet je het zeker?"
             @confirm="handleAction('REGENERATECONTRACT')"
            >
             <a-button :loading="loadingPdfRegenerateOrder" class="btn-signin w-100" size="large" type="primary">
              Contract hergenereren
             </a-button>
            </a-popconfirm>
           </div>
          </a-col>
          <a-col v-if="order && order.abilities.actions.canResendSignSMS" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-popconfirm
             cancel-text="Nee"
             ok-text="Ja"
             placement="leftTop"
             title="Weet je het zeker?"
             @confirm="handleAction('OFFERSMS')"
            >
             <a-button class="btn-signin w-100" size="large" type="primary"> SMS verzenden
             </a-button>
            </a-popconfirm>
           </div>
          </a-col>
          <a-col v-if="order && order.abilities.actions.canResendConfirmationMail" :lg="24" :md="24" :xl="24" :xs="24"
                 :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-popconfirm
             cancel-text="Nee"
             ok-text="Ja"
             placement="leftTop"
             title="Weet je het zeker?"
             @confirm="handleAction('CONFIRMATIONMAIL')"
            >
             <a-button class="btn-signin w-100" size="large" type="primary"> Bevestiging opnieuw
              verzenden
             </a-button>
            </a-popconfirm>
           </div>
          </a-col>
          <a-col v-if="order && order.abilities.actions.canResendSignEmail" :lg="24" :md="24" :xl="24" :xs="24"
                 :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-popconfirm
             cancel-text="Nee"
             ok-text="Ja"
             placement="leftTop"
             title="Weet je het zeker?"
             @confirm="handleAction('OFFERMAIL')"
            >
             <a-button class="btn-signin w-100" size="large" type="primary"> Aanbod opnieuw verzenden
             </a-button>
            </a-popconfirm>
           </div>
          </a-col>
          <!--          <a-col v-if="order && order.abilities.actions.canSendExternal" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"-->
          <!--                 class="mb-5">-->
          <!--           <div class="sDash_form-action">-->
          <!--            <a-popconfirm-->
          <!--             cancel-text="Nee"-->
          <!--             ok-text="Ja"-->
          <!--             placement="leftTop"-->
          <!--             title="Weet je het zeker? U kunt dit niet meer terugdraaien!"-->
          <!--             @confirm="handleAction('SENDEXTERNAL')"-->
          <!--            >-->
          <!--             <a-button :loading="loadingSendExternalOrder" class="btn-signin w-100" size="large" type="primary">-->
          <!--              Verzenden naar leverancier-->
          <!--             </a-button>-->
          <!--            </a-popconfirm>-->
          <!--           </div>-->
          <!--          </a-col>-->
          <a-col v-if="order && order.abilities.actions.canSignManual" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-popconfirm
             cancel-text="Nee"
             ok-text="Ja"
             placement="leftTop"
             title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
             @confirm="handleAction('SIGNMANUAL')"
            >
             <a-button class="btn-signin w-100" size="large" type="primary"> Manueel tekenen
             </a-button>
            </a-popconfirm>
           </div>
          </a-col>
          <a-col v-if="order && (order.abilities.actions.canCancel || order.abilities.actions.canCancelAtSupplier)"
                 :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
                 class="mb-5">
           <div class="sDash_form-action">
            <a-button class="btn-signin w-100" size="large" type="danger"
                      @click.prevent="handleAction('CANCEL')"> Annuleren
            </a-button>
           </div>
          </a-col>
         </a-row>
        </sdCards>
       </a-col>
      </a-row>
      <a-row :gutter="25">
       <a-col :xs="24">
        <sdCards title="Documenten">
         <FilesListTable @showPdfViewer="showPdfViewer"/>
         <a-row class="mt-6">
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
             Toevoegen
            </a-button>
           </div>
          </a-col>
         </a-row>
        </sdCards>
       </a-col>
      </a-row>
      <a-row
       v-if="order && order.type && (order.type === 'ENERGY' || order.type === 'SOLARSIMPLE' || order.type === 'SOLAR' || order.type === 'HYDROMX' || order.type === 'LEAD')"
       :gutter="25">
       <a-col :xs="24">
        <sdCards title="Productvragen">
         <a-form :model="{flowQuestions:flowQuestions}" layout="vertical" @finish="UpdateFlowQuestions">
          <a-row :gutter="25">
           <a-col v-for="(item,index) in flowQuestions" :key="index" :md="12" :xs="24">
            <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                         :name="['flowQuestions', index, 'value']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <DatePickerWrapper>
              <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                             :disabled="order && !order.abilities.actions.canSendExternal"
                             :disabled-date="disabledStartDate"
                             :format="dateFormat"
                             :locale="locale"
                             class="w-100"
              />
             </DatePickerWrapper>
            </a-form-item>
            <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                         :name="['flowQuestions', index, 'value']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <DatePickerWrapper>
              <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()"
                             :disabled="order && !order.abilities.actions.canSendExternal"
                             :format="dateFormat" :locale="locale"
                             class="w-100"/>
             </DatePickerWrapper>
            </a-form-item>
            <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                         :name="['flowQuestions', index, 'value']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-input v-model:value="item.value" :disabled="order && !order.abilities.actions.canSendExternal"
                      placeholder=""/>
            </a-form-item>
            <a-form-item v-if="item.inputType === 'INTEGER'" :label="item.displayName"
                         :name="['flowQuestions', index, 'value']"
                         :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                         onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
             <a-input v-model:value="item.value" :disabled="order && !order.abilities.actions.canSendExternal"
                      placeholder="" step="1"
                      type="text"/>
            </a-form-item>
            <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                         :name="['flowQuestions', index, 'value']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-select v-model:value="item.value" :allowClear="true"
                       :disabled="order && !order.abilities.actions.canSendExternal"
                       class="sDash_fullwidth-select" size="large">
              <a-select-option v-for="(option,index) in item.inputValues"
                               :key="index"
                               :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
          </a-row>
          <a-row>
           <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
             <a-button :disabled="loadingPatchOrderFlowQuestions || order && !order.abilities.actions.canSendExternal"
                       :loading="loadingPatchOrderFlowQuestions" class="btn-signin" html-type="submit"
                       size="large"
                       type="primary">
              Opslaan
             </a-button>
            </div>
           </a-col>
          </a-row>
         </a-form>
        </sdCards>
       </a-col>
      </a-row>
      <a-row :gutter="25">
       <a-col :md="12" :xs="24">
        <TimelineBoxWrap>
         <sdCards title="Activiteit">
          <a-timeline mode="alternate">
           <a-timeline-item v-for="(item,index) in activity" :key="index">
            <template #dot>
             <SwEllipse color="#ADB4D2" size="10"/>
            </template>
            <h2><small>{{ moment(item.timestamp).format('DD-MM-YYYY HH:mm:ss') }} -
             {{ item.userFullName }}</small></h2>
            <div class="content-box">
             <div>
              {{ item.description }}
             </div>
            </div>
           </a-timeline-item>
          </a-timeline>
         </sdCards>
        </TimelineBoxWrap>
       </a-col>
       <a-col :md="12" :xs="24">
        <TimelineBoxWrap>
         <sdCards title="Communicatie">
          <a-timeline mode="alternate">
           <a-timeline-item v-for="(item,index) in communication" :key="index">
            <template #dot>
             <SwEllipse color="#ADB4D2" size="10"/>
            </template>
            <h2><small>{{ moment(item.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }} -
             {{ item.userFullName }}</small></h2>
            <div class="content-box">
             <div>
                            <span class="mb-5">
                              {{ item.text }}
                            </span>
              <StepsStyle :current="item.currentStep" :status="item.failReason ? 'error' : ''"
                          class="mt-5" direction="vertical" size="small" step="direction">
               <a-step v-for="item in item.steps" :key="item.id" :description="item.description"
                       :title="item.title"/>
              </StepsStyle>

             </div>
            </div>
           </a-timeline-item>
          </a-timeline>
         </sdCards>
        </TimelineBoxWrap>
       </a-col>
      </a-row>
     </div>
     <sdCards>
      <a-row>
       <a-col :sm="12" :xs="24">
        <div class="sDash_order-action">
         <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
          Terug
         </sdButton>
        </div>
       </a-col>
      </a-row>
     </sdCards>
    </Child>
    <Child v-if="order && order.type && order.type === 'ENERGY' &&  order.abilities.tabs.energy" :key="2">
     <template #tab>
        <span>
          <sdFeatherIcons size="18" type="box"/>
          Energie
        </span>
     </template>
     <div v-if="order">
      <a-row :gutter="25">
       <a-col v-if="!order.productRevision.isProxy" :md="14" :xs="24">
        <Aom :calculations="energyCalculation" :type="order.relationRevision.type"></Aom>
       </a-col>
       <a-col :md="10" :xs="24">
        <Suspense>
         <template #default>
          <RelationCard :order="order"/>
         </template>
        </Suspense>
       </a-col>
      </a-row>
      <a-row v-if="connections.filter(i => i.marketSegment ==='ELECTRICITY').length > 0" :gutter="25">
       <a-col :xs="24">
        <sdCards title="Stroom connecties">
         <a-form ref="formRef" :model="{connections:connections}" layout="vertical">
          <a-row justify="center">
           <a-col :sm="24" :xs="24">
            <ConnectionDetailsWrapper>
             <a-col class="about-connection-wrapper">
              <ConnectionCardWrapper headless>
               <div v-for="(item, index) in connections" :key="index">
                <CardWrapper v-if="item.marketSegment ==='ELECTRICITY'">
                 <a-form :model="{ connections:connections}" layout="vertical"
                         @finish="UpdateConnection(index)">
                  <figcaption
                   style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                   <div class="mt-5 mb-0">
                    <a-row :gutter="30" class="mb-20">
                     <a-col :xs="24"
                            style="vertical-align: middle;display: table-cell;align-self: center;">
                      <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                       Adres:
                       <span>{{ item.street }} {{
                         item.houseNumber
                        }} {{
                         item.houseNumberAddition ? item.houseNumberAddition : ''
                        }}, {{ item.postalCode }} {{ item.city }}, Nederland</span><br/>
                       Gebruiksdoel: {{ item.bagPurpose }}
                      </strong>
                     </a-col>
                    </a-row>
                    <a-row :gutter="30">
                     <a-col :xs="24">
                      <a-form :model="{ connections:connections}" layout="vertical"
                              @finish="UpdateConnection(index, 1)">
                       <a-row :gutter="30">
                        <a-col :sm="6" :xs="24">
                         <a-form-item :key="index"
                                      :name="['connections', index, 'capacityCode']"
                                      label="Aansluiting">
                          <a-select v-model:value="item.capacityCode"
                                    :allowClear="true"
                                    :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                    :rules="{required: true,message: 'Dit veld is verplicht'}"
                                    class="sDash_fullwidth-select"
                                    size="large">
                           <a-select-option v-for="(item,index) in capacityCodeElectricityOptions"
                                            :key="index"
                                            :name="['connections', index, 'capacityCode']" :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </a-col>
                        <a-col :sm="4" :xs="24">
                         <a-form-item :name="['connections', index, 'isResidenceFunction']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Woon-/verblijfsfunctie">
                          <a-select v-model:value="item.isResidenceFunction"
                                    :allowClear="true"
                                    :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                    class="sDash_fullwidth-select"
                                    size="large">
                           <a-select-option
                            v-for="(item,index) in defaultOptions"
                            :key="index"
                            :name="['connections', index, 'isResidenceFunction']" :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </a-col>
                        <a-col :sm="4" :xs="24">
                         <a-form-item :name="['connections', index, 'switchType']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Inhuizing">
                          <a-select v-model:value="item.switchType"
                                    :allowClear="true"
                                    :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                    class="sDash_fullwidth-select"
                                    size="large">
                           <a-select-option v-for="(item,index) in switchTypeOptions"
                                            :key="index"
                                            :name="['connections', index, 'switchType']" :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </a-col>
                        <a-col :sm="4" :xs="24">
                         <a-form-item :name="['connections', index, 'meterType']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Dubbele meter">
                          <a-select v-model:value="item.meterType"
                                    :allowClear="true"
                                    :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                    class="sDash_fullwidth-select"
                                    size="large">
                           <a-select-option v-for="(item,index) in meterTypeOptions"
                                            :key="index"
                                            :name="['connections', index, 'meterType']" :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </a-col>
                        <a-col :sm="4" :xs="24">
                         <a-form-item :name="['connections', index, 'hasReturn']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Teruglevering">
                          <a-select v-model:value="item.hasReturn"
                                    :allowClear="true"
                                    :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                    class="sDash_fullwidth-select"
                                    size="large"
                                    @change="connections[index].returnElectricitySingle === null;connections[index].returnElectricityHigh === null;connections[index].returnElectricityLow === null;">
                           <a-select-option v-for="(item,index) in defaultOptions"
                                            :key="index"
                                            :name="['connections', index, 'hasReturn']" :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </a-col>
                       </a-row>
                       <a-row :gutter="30">
                        <a-col v-if="item.meterType === 'SINGLE'" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'usageElectricitySingle']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Elektriciteit  enkel">
                          <a-input :key="index" v-model:value="item.usageElectricitySingle"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'usageElectricitySingle']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"
                          />
                         </a-form-item>
                        </a-col>
                        <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'usageElectricityHigh']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Elektriciteit  normaal">
                          <a-input v-model:value="item.usageElectricityHigh"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'usageElectricityHigh']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"/>
                         </a-form-item>
                        </a-col>
                        <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'usageElectricityLow']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Elektriciteit  dal">
                          <a-input v-model:value="item.usageElectricityLow"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'usageElectricityLow']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"/>
                         </a-form-item>
                        </a-col>
                        <a-col v-if="item.meterType === 'SINGLE' && item.hasReturn" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'returnElectricitySingle']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Teruglevering  enkel">
                          <a-input v-model:value="item.returnElectricitySingle"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'returnElectricitySingle']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"/>
                         </a-form-item>
                        </a-col>
                        <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'returnElectricityHigh']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Teruglevering  normaal">
                          <a-input v-model:value="item.returnElectricityHigh"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'returnElectricityHigh']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"/>
                         </a-form-item>
                        </a-col>
                        <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                               :xs="24">
                         <a-form-item :name="['connections', index, 'returnElectricityLow']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="Teruglevering  dal">
                          <a-input v-model:value="item.returnElectricityLow"
                                   :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                   :name="['connections', index, 'returnElectricityLow']"
                                   addon-after="kWh"
                                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                   placeholder=""
                                   type="number"/>
                         </a-form-item>
                        </a-col>
                       </a-row>
                       <a-row>
                        <a-col :sm="12" :xs="24" class="mb-5">
                         <div class="sDash_form-action">
                          <a-button
                           :disabled="(loadingPatchOrderConnection && selectedPatchOrderConnection === 1) || !order.abilities.actions.canChangeEnergyConnections"
                           :loading="loadingPatchOrderConnection && selectedPatchOrderConnection === 1"
                           class="btn-signin"
                           html-type="submit"
                           size="large"
                           type="primary">
                           Opslaan
                          </a-button>
                         </div>
                        </a-col>
                       </a-row>
                      </a-form>
                     </a-col>
                    </a-row>
                   </div>
                  </figcaption>
                 </a-form>
                </CardWrapper>
               </div>
              </ConnectionCardWrapper>
             </a-col>
            </ConnectionDetailsWrapper>
           </a-col>
          </a-row>
         </a-form>
        </sdCards>
       </a-col>
      </a-row>
      <a-row v-if="connections.filter(i => i.marketSegment ==='GAS').length > 0" :gutter="25">
       <a-col :xs="24">
        <sdCards title="Gas connecties">

         <a-row justify="center">
          <a-col :sm="24" :xs="24">
           <ConnectionDetailsWrapper>
            <a-col class="about-connection-wrapper">
             <ConnectionCardWrapper headless>
              <div v-for="(item, index) in connections" :key="index">
               <CardWrapper v-if="item.marketSegment ==='GAS'">
                <a-form :model="{ connections:connections}" layout="vertical"
                        @finish="UpdateConnection(index, 2)">
                 <figcaption
                  style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                  <div class="mt-0 mb-0">
                   <a-row :gutter="30" class="mb-20">
                    <a-col :xs="24"
                           style="vertical-align: middle;display: table-cell;align-self: center;">
                     <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                      Adres: <span>{{ item.street }} {{
                        item.houseNumber
                       }} {{
                        item.houseNumberAddition ? item.houseNumberAddition : ''
                       }}, {{ item.postalCode }} {{ item.city }}, Nederland</span><br/>Gebruiksdoel:
                      {{ item.bagPurpose }}
                     </strong>
                    </a-col>
                   </a-row>
                   <a-row :gutter="30">
                    <a-col :xs="24">
                     <a-row :gutter="30">
                      <a-col :sm="6" :xs="24">
                       <a-form-item :name="['connections', index, 'capacityCode']"
                                    :rules="{required: true,message: 'Dit veld is verplicht'}"
                                    label="Aansluiting">
                        <a-select v-model:value="item.capacityCode" :allowClear="true"
                                  :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                  class="sDash_fullwidth-select" name="capacityCode"
                                  size="large">
                         <a-select-option v-for="(capacityCode,capacityCodeIndex) in capacityCodeGasOptions"
                                          :key="capacityCodeIndex"
                                          :name="['connections', index, 'capacityCode']" :value="capacityCode.value">
                          {{ capacityCode.key }}
                         </a-select-option>
                        </a-select>
                       </a-form-item>
                      </a-col>
                      <a-col :sm="4" :xs="24">
                       <a-form-item :name="['connections', index, 'switchType']"
                                    :rules="{required: true,message: 'Dit veld is verplicht'}"
                                    label="Inhuizing">
                        <a-select v-model:value="item.switchType" :allowClear="true"
                                  :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                  class="sDash_fullwidth-select"
                                  size="large">
                         <a-select-option v-for="(item,index) in switchTypeOptions"
                                          :key="index"
                                          :name="['connections', index, 'switchType']"
                                          :value="item.value">
                          {{ item.key }}
                         </a-select-option>
                        </a-select>
                       </a-form-item>
                      </a-col>
                      <a-col :sm="6" :xs="24">
                       <a-form-item :name="['connections', index, 'usageGas']"
                                    :rules="{required: true,message: 'Dit veld is verplicht'}"
                                    label="Verbruik">
                        <a-input v-model:value="item.usageGas"
                                 :disabled="!order.abilities.actions.canChangeEnergyConnections"
                                 addon-after="m3"
                                 onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                 onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                 placeholder=""
                                 type="number"
                        />
                       </a-form-item>
                      </a-col>
                     </a-row>
                     <a-row>
                      <a-col :sm="12" :xs="24" class="mb-5">
                       <div class="sDash_form-action">
                        <a-button
                         :disabled="(loadingPatchOrderConnection && selectedPatchOrderConnection === 2) || !order.abilities.actions.canChangeEnergyConnections"
                         :loading="loadingPatchOrderConnection && selectedPatchOrderConnection === 2" class="btn-signin"
                         html-type="submit"
                         size="large"
                         type="primary">
                         Opslaan
                        </a-button>
                       </div>
                      </a-col>
                     </a-row>
                    </a-col>
                   </a-row>
                  </div>
                 </figcaption>
                </a-form>
               </CardWrapper>
              </div>
             </ConnectionCardWrapper>
            </a-col>
           </ConnectionDetailsWrapper>
          </a-col>
         </a-row>
        </sdCards>
       </a-col>
      </a-row>
      <sdCards>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_order-action">
          <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
           Terug
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
    <Child v-if="order && order.type && order.type === 'SVM' && order.abilities.tabs.svm" :key="2">
     <template #tab>
      <div @click.prevent="fetchAnalyze">
       <sdFeatherIcons size="18" type="box"/>
       SVM Analyse
      </div>
     </template>
     <a-row :gutter="25">
      <a-col :xs="24">
       <div v-if="loadingFetchSVMQuestions" class="spin" style="height:200px">
        <a-spin/>
       </div>
       <sdModal :onCancel="handleComponentEnergyAddModalCancel"
                :visible="visibleComponentEnergyAddModal"
                size="small"
                title="Component toevoegen"
                type="primary">
        <ModalMain>
         <div class="create-account-form">
          <a-form
           :model="selectedComponent"
           :rules="rulesAddComponent"
           layout="vertical"
           @finish="handleAddComponent"
          >
           <a-row :gutter="30">
            <a-col :xs="24">
             <a-form-item
              label="Omschrijving"
              name="selectedComponent">
              <a-select v-model:value="selectedComponent.selectedComponent" :allowClear="true"
                        class="sDash_fullwidth-select"
                        name="selectedComponent"
                        size="large">
               <a-select-option v-for="(item,index) in svmEnergyCategoryOptions"
                                :key="index"
                                :value="JSON.stringify(item)" name="selectedComponent">
                {{ item.description }}
               </a-select-option>
              </a-select>
             </a-form-item>
            </a-col>
            <a-col :xs="24">
             <sdButton style="width:100%" type="primary">
              <sdFeatherIcons size="16" type="plus"/>
              Toevoegen
             </sdButton>
            </a-col>
           </a-row>
          </a-form>
         </div>
        </ModalMain>
       </sdModal>
       <sdModal :onCancel="handleComponentGasAddModalCancel"
                :visible="visibleComponentGasAddModal"
                size="small"
                title="Component toevoegen"
                type="primary">
        <ModalMain>
         <div class="create-account-form">
          <a-form
           :model="selectedComponent"
           :rules="rulesAddComponent"
           layout="vertical"
           @finish="handleAddComponent"
          >
           <a-row :gutter="30">
            <a-col :xs="24">
             <a-form-item
              label="Omschrijving"
              name="selectedComponent">
              <a-select v-model:value="selectedComponent.selectedComponent" :allowClear="true"
                        class="sDash_fullwidth-select"
                        name="selectedComponent"
                        size="large">
               <a-select-option v-for="(item,index) in svmGasCategoryOptions"
                                :key="index"
                                :value="JSON.stringify(item)" name="selectedComponent">
                {{ item.description }}
               </a-select-option>
              </a-select>
             </a-form-item>
            </a-col>
            <a-col :xs="24">
             <sdButton style="width:100%" type="primary">
              <sdFeatherIcons size="16" type="plus"/>
              Toevoegen
             </sdButton>
            </a-col>
           </a-row>
          </a-form>
         </div>
        </ModalMain>
       </sdModal>
       <a-form ref="questionsForm" :model="svmQuestions" layout="vertical">
        <sdCards v-if="!loadingFetchSVMQuestions"
                 title="Huidige elektriciteit en gas situatie">
         <a-row :gutter="25" class="w-100">
          <a-col :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Elektriciteitsprijs"
                        name="energyTariff">
            <a-input v-model:value="svmQuestions.energyTariff"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     addon-after="per kWh"
                     addon-before="€"
                     name="energyTariff"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Gassprijs"
                        name="gasTariff">
            <a-input v-model:value="svmQuestions.gasTariff"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     addon-after="per m3"
                     addon-before="€"
                     name="gasTariff"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Heeft zonnepanelen?"
                        name="hasSolar">
            <a-select v-model:value="svmQuestions.hasSolar"
                      :allowClear="true"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      class="sDash_fullwidth-select"
                      name="hasSolar"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions"
                              :key="index"
                              :value="item.value"
                              name="hasSolar">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="!svmQuestions.hasSolar" :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zonnepanelen mogelijk?"
                        name="solarAvailable">
            <a-select v-model:value="svmQuestions.solarAvailable"
                      :allowClear="true"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      class="sDash_fullwidth-select"
                      name="solarAvailable"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions"
                              :key="index"
                              :value="item.value"
                              name="solarAvailable">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="!svmQuestions.hasSolar && svmQuestions.solarAvailable" :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Dak oppervlakte"
                        name="roofSurface">
            <a-input v-model:value="svmQuestions.roofSurface"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     addon-after="m2"
                     name="roofSurface"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Heeft slimme thermostaat?"
                        name="hasSmartThermostat">
            <a-select v-model:value="svmQuestions.hasSmartThermostat"
                      :allowClear="true"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      class="sDash_fullwidth-select"
                      name="hasSmartThermostat"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions"
                              :key="index"
                              :value="item.value"
                              name="hasSmartThermostat">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
        </sdCards>
        <sdCards v-if="!loadingFetchSVMQuestions" title="Energieverbruik per onderdeel">
         <div v-for="(item,index) in svmQuestions.usages" :key="index">
          <a-row v-if="item.type === 'ENERGY'" :gutter="25"
                 class="w-100">
           <a-col :md="8" :xs="24">
            <a-form-item label="Omschrijving">
             <a-input v-model:value="item.description" :disabled="true"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item label="Type">
             <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item :name="['usages', index, 'amount']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Aantal">
             <a-input v-model:value="item.amount" :addon-after="item.unit"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      :name="['usages', index, 'amount']"
                      onkeydown="if(event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""
                      @input="item.usage = (item.amount * item.amountPerUnit).toFixed(0)"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item :name="['usages', index, 'usage']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verbruik">
             <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      :name="['usages', index, 'usage']"
                      onkeydown="if(event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col v-if="!item.default" :md="4" :xs="24">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger" @click.prevent="handleRemoveComponent(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </a-row>
         </div>
         <a-row v-if="order && order.abilities.actions.canSendExternal">
          <a-col :xs="24">
           <hr/>
          </a-col>
         </a-row>
         <a-row v-if="order && order.abilities.actions.canSendExternal">
          <a-col :xs="24" class="mt-6">
           <sdButton class="ml-4" size="large"
                     type="primary"
                     @click.prevent="visibleComponentEnergyAddModal = true">
            <sdFeatherIcons size="14" type="plus"/>
            Onderdeel toevoegen
           </sdButton>
          </a-col>
         </a-row>
        </sdCards>
        <sdCards v-if="!loadingFetchSVMQuestions" title="Gasverbruik per onderdeel">
         <div v-for="(item,index) in svmQuestions.usages" :key="index">
          <a-row v-if="item.type === 'GAS'" :gutter="25"
                 class="w-100">
           <a-col :md="8" :xs="24">
            <a-form-item label="Omschrijving">
             <a-input v-model:value="item.description" :disabled="true"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item label="Type">
             <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item :name="['usages', index, 'amount']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Aantal">
             <a-input v-model:value="item.amount" :addon-after="item.unit"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      :name="['usages', index, 'amount']"
                      onkeydown="if(event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""
                      @input="item.usage = (item.amount * item.amountPerUnit).toFixed(0)"/>
            </a-form-item>
           </a-col>
           <a-col :md="4" :xs="24">
            <a-form-item :name="['usages', index, 'usage']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verbruik">
             <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      :name="['usages', index, 'usage']"
                      onkeydown="if(event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col v-if="!item.default" :md="4" :xs="24">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger" @click.prevent="handleRemoveComponent(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </a-row>
         </div>
         <a-row v-if="order && order.abilities.actions.canSendExternal">
          <a-col :xs="24">
           <hr/>
          </a-col>
         </a-row>
         <a-row v-if="order && order.abilities.actions.canSendExternal">
          <a-col :xs="24" class="mt-6">
           <sdButton class="ml-4" size="large" type="primary"
                     @click.prevent="visibleComponentGasAddModal = true">
            <sdFeatherIcons size="14" type="plus"/>
            Onderdeel toevoegen
           </sdButton>
          </a-col>
         </a-row>
        </sdCards>
        <sdCards v-if="!loadingFetchSVMQuestions" title="Verlichting">
         <a-row :gutter="25" class="w-100">
          <a-col :md="12" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Oude verlichting"
                        name="oldLightingCount">
            <a-input v-model:value="svmQuestions.oldLightingCount"
                     :addon-after="parseInt(svmQuestions.oldLightingCount) > 0 ? 'lampen' : 'lamp'"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     name="oldLightingCount"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
          <a-col :md="12" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="LED Verlichting"
                        name="newLightingCount">
            <a-input v-model:value="svmQuestions.newLightingCount"
                     :addon-after="parseInt(svmQuestions.newLightingCount) > 0 ? 'lampen' : 'lamp'"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     name="newLightingCount"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row v-if="!lampCheck" class="w-100">
          <a-col :xs="24">
           <sdAlerts
            :closable="false"
            :description="`De totaal aantal lampen ${totalLamps}  stuk(s) komt niet overeen met de opgegeven aantal oude en led verlichting.`"
            :outlined="false"
            :showIcon="true"
            message=""
            type="warning"
           />
          </a-col>
         </a-row>
        </sdCards>
        <sdCards v-if="!loadingFetchSVMQuestions" title="Glas">
         <a-row :gutter="25" class="w-100">
          <a-col :md="12" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Uw huidige soort glas"
                        name="currentGlassType">
            <a-select v-model:value="svmQuestions.currentGlassType" :allowClear="true"
                      :disabled="order && !order.abilities.actions.canSendExternal"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in glassSVMOptions"
                              :key="index"
                              :value="item.value"
                              name="currentGlassType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :md="12" :xs="24">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Glasoppervlakte"
                        name="glassSurface">
            <a-input v-model:value="svmQuestions.glassSurface"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     addon-after="m2"
                     name="glassSurface"
                     onkeydown="if(event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""/>
           </a-form-item>
          </a-col>
         </a-row>
        </sdCards>
        <a-row v-if="!svmAvailable" class="w-100 mb-20">
         <a-col :xs="24">
          <sdAlerts
           :closable="false"
           :outlined="false"
           :showIcon="true"
           description="Het jaarlijks energieverbruik per bedrijfspand moet minder dan 25.000 m3 aardgas en minder dan 50.000 kWh elektriciteit zijn om te voldoen aan de minimale eisen voor een SVNM subsidie aanvraag."
           message=""
           type="warning"
          />
         </a-col>
        </a-row>
        <sdCards>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_order-action">
            <sdButton class="btn-signin mr-4" type="light" @click="$router.replace('/order')">
             Terug
            </sdButton>
            <a-button :disabled="loadingPatchSVMQuestions || (order && !order.abilities.actions.canSendExternal)"
                      :loading="loadingPatchSVMQuestions" class="btn-signin"
                      size="large"
                      type="primary"
                      @click.prevent="handlePatchSVMQuestions"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </sdCards>
       </a-form>
      </a-col>
     </a-row>
    </Child>
    <Child v-if="order && order.type && (order.type === 'SOLAR' || order.type === 'SOLARCONFIRM')" :key="2">
     <template #tab>
        <span>
          <sdFeatherIcons size="18" type="home"/>
          Dakdelen & Opbrengst
        </span>
     </template>
     <a-row :gutter="25">
      <a-col :md="8" :xs="24">
       <sdCards
        v-if="solarData && solarData.satelliteImageFileURL"
        title="Satteliet afbeelding">
        <img :src="solarData.satelliteImageFileURL" alt="" height="250" style="width: 100%">
       </sdCards>
      </a-col>
      <a-col :md="16" :xs="24">
       <sdCards
        title="Geselecteerde dakdelen">
        <RoofSelectionTable :data="solarData.surfaces"/>
       </sdCards>
      </a-col>
     </a-row>
     <a-row
      v-if="solarData && solarData.calculation && solarData.calculation.monthChartImageURL && solarData.calculation.yearChartImageURL"
      :gutter="25">
      <a-col :md="12" :xs="24">
       <sdCards
        :title="`Opbrengst per maand ${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()}`"
        style="text-align: center">
        <img :src="solarData.calculation.monthChartImageURL" alt="" height="300" style="width: auto">
       </sdCards>
      </a-col>
      <a-col :md="12" :xs="24">
       <sdCards style="text-align: center" title="Opbrengst per jaar">
        <img :src="solarData.calculation.yearChartImageURL" alt="" height="300" style="width: auto">
       </sdCards>
      </a-col>
     </a-row>
    </Child>
    <Child v-if="order && order.abilities.tabs.relation" :key="3">
     <template #tab>
        <span>
          <sdFeatherIcons size="18" type="users"/>
          Relatie
        </span>
     </template>
     <p v-if="order">
      <Relation
       :can-update-relation="order.abilities.actions.canUpdateRelation"
       :order-id="orderId"
       :relation="order.relationRevision"
       :show-card-save-button="true"
       :show-footer="false"
       :show-global-save-button="false"
       type="ORDEREDIT"
      ></Relation>
     </p>
     <sdCards>
      <a-row>
       <a-col :sm="12" :xs="24">
        <div class="sDash_order-action">
         <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
          Terug
         </sdButton>
        </div>
       </a-col>
      </a-row>
     </sdCards>
    </Child>
    <Child v-if="order && order.abilities.tabs.script" :key="4">
     <template #tab>
        <span>
          <sdFeatherIcons size="18" type="file-text"/>
          Script
        </span>
     </template>
     <div v-if="script">
      <sdCards title="Voicelog">
       <a-row :gutter="30">
        <a-col :sm="4" :xs="24" class="mb-25" style="width: 100%;min-width: 100%;">
         <div v-html="script"></div>
        </a-col>
       </a-row>
      </sdCards>
      <sdCards>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_order-action">
          <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
           Terug
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
  </Main>
 </div>
</template>

<script>
import {InfoWrapper, Main, TimelineBoxWrap} from '../styled';
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {Child, TabBasic} from "../../components/tabs/Style";
import Aom from "../../components/aom/Aom";
import ProductInfoCard from "./component/ProductInfoCard";
import RelationCard from "./component/RelationCard";
import RelationSVMCard from "./component/RelationSVMCard";
import Relation from "../../components/relation/Relation";
import {SwEllipse} from "../../components/utilities/icons";
import moment from "moment-timezone";
import 'moment/dist/locale/nl';
import FilesListTable from "./component/FilesTable";
import {
 capacityCodeElectricityOptions,
 capacityCodeGasOptions,
 defaultOptions,
 fileOptions,
 glassSVMOptions,
 meterTypeOptions,
 svmEnergyCategoryOptions,
 svmGasCategoryOptions,
 switchTypeOptions
} from "../../utility/enums";
import {StepsStyle} from "./Style";
import PdfViewer from "@/components/pdfViewer/PdfViewer";
import {getApiURL} from "@/config/dataService/dataService";
import {integerValidator} from "@/utility/validators";
import ProductPriceSolarSimpleInfoCard from "@/view/orders/component/ProductPriceSolarSimpleInfoCard";
import OrderDetailShop from "@/view/orders/component/OrderDetailShop";
import ProductPriceSolarInfoCard from "@/view/orders/component/ProductPriceSolarInfoCard";
import RoofSelectionTable from "@/view/orders/component/RoofSelectionTable";
import locale from 'ant-design-vue/es/date-picker/locale/nl_NL';
import {datePlusDays} from "@/utility/utility";
import {useRouter} from "vue-router/dist/vue-router";
import ProductPriceSolarConfirmInfoCard from "@/view/orders/component/ProductPriceSolarConfirmInfoCard";

moment.tz.setDefault("Europe/Berlin");
export default defineComponent({
 name: 'OrderEdit',
 components: {
  FilesListTable,
  Main,
  TabBasic,
  Child,
  Aom,
  ProductInfoCard,
  RelationCard,
  Relation,
  InfoWrapper,
  SwEllipse,
  TimelineBoxWrap,
  StepsStyle,
  RelationSVMCard,
  PdfViewer,
  ProductPriceSolarSimpleInfoCard,
  OrderDetailShop,
  ProductPriceSolarInfoCard,
  RoofSelectionTable,
  ProductPriceSolarConfirmInfoCard,
 },
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const {params} = useRoute();
  let key = ref(0);
  const order = reactive(computed(() => {
   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
   key.value += key.value + Math.random();
   return state.order.order;
  }));
  const script = reactive(computed(() => state.order.script));
  const connections = reactive(computed(() => state.order.connections));
  const communication = reactive(computed(() => state.order.communication));
  const activity = reactive(computed(() => state.order.activity));
  const energyCalculation = reactive(computed(() => state.order.energyCalculation));
  const statusses = reactive(computed(() => state.order.statusses));
  const flowQuestions = reactive(computed(() => {
   if (state.order.order.type === "SOLARSIMPLE" || state.order.order.type === "SOLAR" || state.order.order.type === "HYDROMX") {
    return state.question.questions
   }
   return state.order.flowQuestions
  }));
  const loadingPatchOrderFlowQuestions = reactive(computed(() => state.order.loadingPatchOrderFlowQuestions));
  const loadingPatchOrderConnection = reactive(computed(() => state.order.loadingPatchOrderConnection));
  const loadingOrderFileUpload = reactive(computed(() => state.order.loadingOrderFileUpload));
  const loadingSendExternalOrder = reactive(computed(() => state.order.loadingSendExternalOrder));
  const loadingPdfRegenerateOrder = reactive(computed(() => state.order.loadingPdfRegenerateOrder));
  const errorOrder = computed(() => state.order.errorOrder);
  const svmQuestions = computed(() => state.svm.svmQuestions);
  const loadingFetchSVMQuestions = computed(() => state.svm.loadingFetchSVMQuestions);
  const loadingPatchSVMQuestions = computed(() => state.svm.loadingPatchSVMQuestions);
  const solarData = computed(() => state.solar.solarData);
  const showFileUploadRef = ref(false);
  const questionsForm = ref();
  const orderId = params.id;
  let selectedPatchOrderConnection = null;
  const fileList = ref([]);
  const showCancelRef = ref(false);
  const showChangeStatus = ref(false);
  const showSignManual = ref(false);
  const signManual = ref({description: ''});
  const visibleComponentEnergyAddModal = ref(false);
  const visibleComponentGasAddModal = ref(false);
  let svmAvailable = ref(true);
  let lampCheck = ref(true);
  let totalLamps = ref(0);
  const pdfViewer = ref();
  const selectedComponent = reactive({
   selectedComponent: null,
  });

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const rules = {
   name: [
    {
     required: true,
     message: 'Please enter user name',
    },
   ],
   description: [
    {
     required: true,
     message: 'Please enter user name',
    },
   ],
  };
  const UpdateOrder = () => {
   dispatch('patchOrder', {
    id: params.id,
    name: order.value.name,
    description: order.value.description,
   });
  };

  const UpdateFlowQuestions = async () => {
   const startDate = flowQuestions.value.find(question => question.inputType === 'STARTDATE');
   for (let i = 0; i < connections.value.length; i++) {
    const conn = connections.value[i];
    conn.startDate = datePlusDays(startDate.value);
    await dispatch('patchConnection', {connection: conn, id: params.id});
   }

   const flowQuestionsUpdate = [];
   for (let i = 0; i < flowQuestions.value.length; i++) {
    if (flowQuestions.value[i].inputType === 'DATE') {
     flowQuestions.value[i].value = datePlusDays(flowQuestions.value[i].value);
    }

    if (flowQuestions.value[i].inputType !== 'STARTDATE') {
     flowQuestionsUpdate.push(flowQuestions.value[i])
    }
   }

   await dispatch('patchOrderFlowQuestions', {id: params.id, flowQuestions: flowQuestionsUpdate});
   if (order.value.type === 'SOLARSIMPLE') {
    await dispatch('getSolarSimpleCalculationForOrder', params.id);
   }
  };

  const fileUpload = reactive(
   {
    type: null,
   }
  );

  const handleFileUploadCancel = () => {
   fileUpload.type = null;
   fileList.value = [];
   showFileUploadRef.value = false;
  };

  const showFileUpload = () => {
   showFileUploadRef.value = true;
  };

  const uploading = ref(false);
  const beforeUpload = (file) => {
   fileList.value = [...fileList.value, file];
   return false;
  };

  const handleRemove = (file) => {
   const index = fileList.value.indexOf(file);
   const newFileList = fileList.value.slice();
   newFileList.splice(index, 1);
   fileList.value = newFileList;
  };

  const handleUpload = async () => {
   let formData = new FormData();
   formData.append("file", fileList.value[0]);
   formData.append("type", fileUpload.type);
   await dispatch('uploadFile', {formData: formData, id: params.id});
   await handleFileUploadCancel();
  }

  const UpdateConnection = async (index, buttonNumber) => {
   selectedPatchOrderConnection = buttonNumber;
   await dispatch('patchConnection', {connection: connections.value[index], id: params.id});
   await dispatch('getOrder', params.id);
  }

  const handleSignManualModalCancel = () => {
   showSignManual.value = false;
   signManual.value.description = '';
  };

  const handleSignManual = async () => {
   await dispatch('signManual', {id: params.id, description: signManual.value.description});
   await dispatch('getOrder', params.id);
   handleSignManualModalCancel();
   showCancelRef.value = false;
  };

  const routeToReOffer = () => {
   router.push('/order/energy/reoffer');
  };

  const routeToOrder = async (id) => {
   await router.push({
    name: 'orderEdit',
    params: {id: id}
   });
   await router.go(0);
  };

  const handleAction = async (action) => {
   if (action === 'OFFERSMS' || action === 'OFFERMAIL' || action === 'CONFIRMATIONMAIL') {
    await dispatch('sendCommunication', {type: action, id: params.id});
    await dispatch('getOrder', params.id);
    return;
   }
   if (action === 'CANCEL') {
    showCancelRef.value = true;
    return;
   }
   if (action === 'SENDEXTERNAL') {
    await dispatch('sendExternal', params.id);
    return;
   }
   if (action === 'REGENERATECONTRACT') {
    await dispatch('pdfRegenerate', params.id);
    await dispatch('getOrder', params.id);
    return;
   }
   if (action === 'SIGNMANUAL') {
    showSignManual.value = true;
    return;
   }
   if (action === 'CHANGESTATUS') {
    showChangeStatus.value = true;
   }
   if (action === 'SHOWOFFERDOCUMENT') {
    pdfViewer.value.showPdf({fileURL: `${getApiURL()}orders/${params.id}/pdf/contract`, fileName: 'Aanbod'}, true);
   }
   if (action === 'CREATECONFIRMORDER') {
    await dispatch('createConfirmOrder', {id: params.id, navigate: routeToOrder});
    return;
   }
   if (action === 'REOFFER') {
    await dispatch('prefillOrder', {
      values: {
       order: order.value,
       connections: connections.value,
       flowQuestions: flowQuestions.value
      },
      navigate: routeToReOffer
     }
    );
   }
  };

  const showPdfViewer = async (data) => {
   pdfViewer.value.showPdf(data, false);
  }

  let cancelOrder = reactive({
   subStatusID: null,
   description: '',
   cancelAtSupplier: false,
   markAsSend: false,
   id: params.id,
  });

  const handleCancelModal = () => {
   showCancelRef.value = false;
  }

  const statusChangeOrder = reactive({
   status: null,
   subStatusID: null,
   description: '',
   id: params.id,
  });

  let subStatusses = ref([])

  const onStatusChange = () => {
   const status = statusses.value.filter(i => i.status === statusChangeOrder.status);
   if (status.length > 0 && status[0].subStatusses.length > 0) {
    subStatusses.value = status[0].subStatusses
   } else {
    subStatusses.value = []
   }
  }

  const handleStatusModalCancel = async () => {
   showChangeStatus.value = false;
  }

  const handleStatusChangeOrder = async () => {
   await dispatch('changeStatus', statusChangeOrder);
   await handleStatusModalCancel();
   await dispatch('getOrder', params.id);
  }

  const handleCancelOrder = async () => {
   cancelOrder.cancelAtSupplier = order.value.isSent;
   await dispatch('cancelOrder', cancelOrder);
   await handleCancelModal();
   await dispatch('getOrder', params.id);
  }

  const fetchAnalyze = async () => {
   await dispatch('fetchSVMQuestions', params.id);
  };

  const handleComponentEnergyAddModalCancel = () => {
   visibleComponentEnergyAddModal.value = false;
  };

  const handleComponentGasAddModalCancel = () => {
   visibleComponentGasAddModal.value = false;
  };

  const handlePatchSVMQuestions = () => {
   svmAvailable.value = true;
   lampCheck.value = true;
   let totalUsageM3 = 0;
   let totalUsageKwh = 0;
   const orderValidators = [questionsForm.value.validate()]
   Promise.all(orderValidators).then(() => {
    svmQuestions.value.usages.forEach((item) => {
     if (item.type === 'GAS') {
      totalUsageM3 += parseInt(item.usage);
     }
     if (item.type === 'ENERGY') {
      totalUsageKwh += parseInt(item.usage);
     }
     if (item.id === 'LIGHTING') {
      totalLamps.value = item.amount;
     }
    });
    if (parseInt(totalLamps.value) !== (parseInt(svmQuestions.value.oldLightingCount) + parseInt(svmQuestions.value.newLightingCount))) {
     lampCheck.value = false;
    }
    if (totalUsageM3 < 25000 && totalUsageKwh < 50000 && lampCheck.value) {
     svmAvailable.value = true;
     dispatch('patchSVMQuestions', params.id);
    } else if (lampCheck.value) {
     svmAvailable.value = false;
    }
   })
  };

  const handleAddComponent = async () => {
   await dispatch('addSVMOnOrderComponent', selectedComponent.selectedComponent);
   handleComponentEnergyAddModalCancel();
   handleComponentGasAddModalCancel();
   selectedComponent.selectedComponent = null;
  };

  const handleRemoveComponent = (index) => {
   dispatch('removeSVMOnOrderComponent', index)
  };

  const disabledStartDate = (current) => {
   if (order.value && order.value.signTimestamp) {
    const endDate = moment(order.value.signTimestamp).add(order.value.productRevision.energySwitchWindowMaxDays > 0 ? order.value.productRevision.energySwitchWindowMaxDays : 90, 'd')
    // const startDate = moment(order.value.signTimestamp).add(order.value.productRevision.energySwitchWindowMinDays > 0 ? (order.value.productRevision.energySwitchWindowMinDays - 1) : 39, 'd')
    return !current.isBetween(order.value.signTimestamp, endDate);
   }

   return true;
  };

  const getDefaultPicker = () => {
   if (order.value && order.value.signTimestamp) {
    return moment(new Date(order.value.signTimestamp));
   }
  };

  onMounted(async () => {
   await dispatch('getOrder', params.id);
   if (order.value.type === 'HYDROMX') {
    await dispatch('getQuestionsForOrder', {id: params.id});
   }
   if (order.value.type === 'SOLARSIMPLE') {
    await dispatch('getQuestionsForOrder', {id: params.id});
    await dispatch('getSolarSimpleCalculationForOrder', params.id);
   }
   if (order.value.type === 'SOLAR' || order.value.type === 'SOLARCONFIRM') {
    await dispatch('getQuestionsForOrder', {id: params.id});
    await dispatch('fetchOrderSolarData', params.id);
   }
   if (order.value.type === 'SHOP' || order.value.type === 'LEAD') {
    await dispatch('getOrderShopFlowQuestions', params.id);
   }
  });

  onBeforeUnmount(() => {
   dispatch('resetOrder');
  });

  return {
   UpdateConnection,
   onStatusChange,
   subStatusses,
   statusChangeOrder,
   handleStatusChangeOrder,
   showChangeStatus,
   handleStatusModalCancel,
   handleCancelOrder,
   cancelOrder,
   handleCancelModal,
   showCancelRef,
   handleAction,
   handleUpload,
   handleRemove,
   fileList,
   beforeUpload,
   uploading,
   UpdateFlowQuestions,
   order,
   rules,
   errorOrder,
   UpdateOrder,
   activeKey: ref(1),
   script,
   energyCalculation,
   moment,
   activity,
   communication,
   connections,
   flowQuestions,
   loadingPatchOrderFlowQuestions,
   switchTypeOptions,
   meterTypeOptions,
   defaultOptions,
   capacityCodeElectricityOptions,
   capacityCodeGasOptions,
   showFileUploadRef,
   handleFileUploadCancel,
   showFileUpload,
   fileOptions,
   fileUpload,
   statusses,
   orderId,
   loadingPatchOrderConnection,
   selectedPatchOrderConnection,
   handleSignManualModalCancel,
   handleSignManual,
   showSignManual,
   signManual,
   loadingOrderFileUpload,
   disabledStartDate,
   getDefaultPicker,
   fetchAnalyze,
   svmQuestions,
   loadingFetchSVMQuestions,
   glassSVMOptions,
   lampCheck,
   handleComponentEnergyAddModalCancel,
   handleComponentGasAddModalCancel,
   selectedComponent,
   rulesAddComponent,
   handleAddComponent,
   svmEnergyCategoryOptions,
   svmGasCategoryOptions,
   visibleComponentEnergyAddModal,
   visibleComponentGasAddModal,
   handleRemoveComponent,
   loadingPatchSVMQuestions,
   handlePatchSVMQuestions,
   svmAvailable,
   questionsForm,
   pdfViewer,
   integerValidator,
   loadingSendExternalOrder,
   loadingPdfRegenerateOrder,
   showPdfViewer,
   ProductPriceSolarInfoCard,
   key,
   solarData,
   locale,
  };
 },
});
</script>
<style>
.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
 font-size: 9px !important;
}

.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
 font-size: 11px !important;
}
</style>
