<template>
 <PdfViewer ref="pdfViewerFile"></PdfViewer>
 <sdModal
  :onCancel="handleAudioPlayerModalCancel"
  :visible="audioPlayerVisible"
  title="Audio player"
  type="primary"
 >
  <ModalMain>
   <div class="create-account-form">
    <a-row :gutter="30">
     <a-col
      :xs="24"
      style="text-align: center"
     >
      <audio
       autoplay
       controls
      >
       <source
        :src="selectedAudioFile"
        type="audio/mpeg"
       >
       <source
        :src="selectedAudioFile"
        type="audio/ogg"
       >
      </audio>
     </a-col>
    </a-row>
   </div>
  </ModalMain>
 </sdModal>
 <TableStyleWrapper2>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="filesTableColumns"
    :dataSource="filesTableData"
    :loading="loadingFiles"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
   />
  </TableWrapper>
 </TableStyleWrapper2>
</template>
<script>
import {TableStyleWrapper2, TableWrapper} from '../../styled';
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import PdfViewer from "../../../components/pdfViewer/PdfViewer";

const filesTableColumns = [
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
 },
 {
  title: 'Omschrijving',
  dataIndex: 'description',
  key: 'description',
 },
 {
  title: 'Aanmaakdatum',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
 },
];
const FilesListTable = defineComponent({
 name: 'FilesListTable',
 components: {TableStyleWrapper2, TableWrapper, PdfViewer},
 emits: ['showPdfViewer'],
 setup(props, {emit}) {
  const {state} = useStore();
  const files = computed(() => state.order.files);
  const loadingFiles = computed(() => state.order.loadingOrderFiles);
  const count = computed(() => state.order.filesCount);
  const pdfViewerFile = ref();
  const selectedAudioFile = ref(null);
  const audioPlayerVisible = ref(false);
  const showPdf = (data) => {
   emit('showPdfViewer', data);
  };
  const handleAudioPlayerModalCancel = async () => {
   audioPlayerVisible.value = false;
   selectedAudioFile.value = null;
  }

  const showAudioPlayerModal = async (file) => {
   selectedAudioFile.value = file;
   audioPlayerVisible.value = true;
  }
  const filesTableData = computed(() =>
   files.value.map((files) => {
    const {id, fileName, description, createTimestamp, typeName, fileURL, type} = files;

    return {
     key: id,
     id: id,
     type: (
      <div class="user-info">
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {typeName}
        </sdHeading>
       </figcaption>
      </div>
     ),
     name: (
      <div class="user-info">
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {fileName}
        </sdHeading>
       </figcaption>
      </div>
     ),
     description: <span>{description}</span>,
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     action: (
      <div class="table-actions" class="btn-icon">
       <a href={fileURL} target="_blank">
        <sdFeatherIcons type="download" size="16"/>
       </a>
       {fileName.includes('.pdf') ? (
        <sdButton onClick={() => showPdf({fileName: fileName, fileURL: fileURL}, false)} className="btn-icon" to="#"
                  type="default" shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       {type === 'VOICELOG' || type === 'CALLRECORDING' ? (
        <sdButton onClick={() => showAudioPlayerModal(fileURL)} className="btn-icon" to="#"
                  type="default" shape="circle">
         <sdFeatherIcons type="headphones" size="16"/>
        </sdButton>
       ) : ('')}
      </div>

      // <a-popconfirm
      //     title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
      //     ok-text="Ja"
      //     cancel-text="Nee"
      //     placement="leftTop"
      //     onConfirm={() => handleDelete(id)}
      // >
      //   <sdButton class="btn-icon" type="default" to="#" shape="circle">
      //     <sdFeatherIcons type="trash-2" size="16" />
      //   </sdButton>
      // </a-popconfirm>
     ),
    };
   }),
  );

  return {
   selectedAudioFile,
   filesTableColumns,
   filesTableData,
   loadingFiles,
   count,
   pdfViewerFile,
   handleAudioPlayerModalCancel,
   audioPlayerVisible
  };
 },
});

export default FilesListTable;
</script>
