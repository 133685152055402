<template>
 <UserBioBox>
  <sdCards title="Contactgegevens">
   <a-row v-if="!order || !order.relationRevision">
    <a-col :xs="24">
     <div class="user-info">
      <a-skeleton active/>
     </div>
    </a-col>
   </a-row>
   <a-row v-else>
    <a-col :md="12" :xs="24">
     <div class="user-info">
      <ul class="user-info__contact">
       <li>
        <template v-if="order.relationRevision.type==='CONSUMER'">
         <sdFeatherIcons size="14" type="user"/>
         <span>{{
           order.relationRevision.gender === 'MALE' ? 'Dhr.' : ''
          }}{{
           order.relationRevision.gender === 'FEMALE' ? 'Mevr.' : ''
          }} {{
           order.relationRevision.firstName
          }}{{
           order.relationRevision.middleName && order.relationRevision.middleName !== '' ? ` ${order.relationRevision.middleName}` : ''
          }} {{ order.relationRevision.lastName }}</span>
        </template>
        <template v-if="order.relationRevision.type==='BUSINESS'">
         <sdFeatherIcons size="14" type="briefcase"/>
         <span>{{ order.relationRevision.companyName }}<br/>{{
           order.relationRevision.companyCocNumber
          }}</span>
        </template>
       </li>
       <li>
        <sdFeatherIcons size="14" type="home"/>
        <span>{{ order.relationRevision.street }} {{
          order.relationRevision.houseNumber
         }}{{
          order.relationRevision.houseNumberAddition && order.relationRevision.houseNumberAddition !== '' ? order.relationRevision.houseNumberAddition : ''
         }}<br/>{{ order.relationRevision.postalCode }} {{
          order.relationRevision.city
         }} {{ order.relationRevision.country === 'NL' ? 'Nederland' : '' }}</span>
       </li>
      </ul>
     </div>
    </a-col>
    <a-col :md="12" :xs="24">
     <div class="user-info">
      <ul class="user-info__contact">
       <li>
        <sdFeatherIcons size="14" type="mail"/>
        <span>{{ order.relationRevision.email }}</span>
       </li>
       <li>
        <sdFeatherIcons size="14" type="phone"/>
        <span>{{ order.relationRevision.phoneNumber }}</span></li>
       <li>
        <sdFeatherIcons size="14" type="credit-card"/>
        <span>{{ order.relationRevision.bankAccountNumber }}<br/>{{ order.relationRevision.debtorName }}</span>
       </li>
      </ul>
     </div>
    </a-col>
   </a-row>
  </sdCards>
 </UserBioBox>
</template>
<script>
import {UserBioBox} from './style';
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
import moment from "moment";

const RelationSVMCard = defineComponent({
 name: 'RelationCard',
 components: {UserBioBox},
 props: {
  order: VueTypes.object,
 },
 setup() {
  return {moment};
 },
});

export default RelationSVMCard;
</script>
